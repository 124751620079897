<template>
  <div class="chat-list-panel" ref="scroller">
    <!-- 消息列表 -->
    <div class="app-content">
      <div class="chat-list" :style="style">
        <chat-bubble
          v-for="(item, index) in messages"
          :key="index"
          :index="index"
          :message="item"
          ref="chatBubble"
        ></chat-bubble>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import BetterScroll from 'better-scroll'
import { throttle } from 'lodash'
import ChatBubble from '@/components/ChatBubble'

export default {
  name: 'ChatView',
  data () {
    return {}
  },
  computed: {
    ...mapState(['ui', 'freshNeed']),
    ...mapGetters(['exam', 'messages']),
    style () {
      console.log(this.level, this.examId)
      return {
        paddingBottom: this.exam ? '50px' : '10px'
      }
    }
  },
  watch: {
    messages () {
      this.scrollToEnd()
      console.log('message updated')
    },
    ui () {
      this.scrollToEnd()
      console.log('ui updated')
    },
    freshNeed () {
      this.$store.commit('updateFreshNeed', false)
      this.scrollToEnd(true)
      console.log('updateFreshNeed updated')
    }
  },
  components: {
    [ChatBubble.name]: ChatBubble
  },
  methods: {
    scrollToEnd: (function () {
      let prevIndex = null
      return throttle(
        function (toBottom = false) {
          // 延迟到 dom 渲染完成之后，执行滚动操作
          setTimeout(() => {
            console.log('=========================')
            // 消息的列表自下而上的查找返回结果
            // 首次进入不需要做滚动
            if (prevIndex > this.messages.length) {
              prevIndex = null
            }
            if (prevIndex !== null) {
              if (toBottom) {
                // 通过组件元素获取到他需要滚动的距离
                const el = this.$refs.chatBubble[this.messages.length - 1].$el
                const scroller = this.$refs.scroller
                scroller.scrollTo(
                  0,
                  el.offsetTop + el.offsetHeight + scroller.offsetHeight
                )
              } else {
                let isFromUserFound = false
                for (let i = this.messages.length; i >= prevIndex; i--) {
                  const message = this.messages[i]
                  // 找到用户输入的问题部分
                  if (message && message.from === 2) {
                    // 通过组件元素获取到他需要滚动的距离
                    const el = this.$refs.chatBubble[i].$el
                    const scroller = this.$refs.scroller
                    scroller.scrollTo(
                      0,
                      // el.offsetTop + (scroller.offsetHeight * 2) / 3
                      el.offsetTop - (scroller.offsetHeight * 1) / 5
                    )

                    prevIndex = i + 1
                    isFromUserFound = true
                    break
                  }
                }
                if (!isFromUserFound) {
                  const index = this.messages.length - 1
                  const el = this.$refs.chatBubble[index].$el
                  const scroller = this.$refs.scroller
                  prevIndex = this.messages.length - 1
                  scroller.scrollTo(
                    0,
                    // el.offsetTop + (scroller.offsetHeight * 2) / 3
                    el.offsetTop - (scroller.offsetHeight * 1) / 5
                  )
                }
              }
            } else {
              prevIndex = this.messages.length - 1
            }
          }, 80)

          // setTimeout(() => {
          //   if (this.scroller) {
          //     // 刷新 scroller 内容的高度
          //     this.scroller.refresh()
          //     // 根据具体的场景判断， scroller 是否需要滚动到底部
          //     if (!this.stateScroll) {
          //       // 初始状态下的对话需要从头展示列表数据
          //       this.scroller.scrollToElement(0, 0, 0)
          //       this.stateScroll = true
          //     } else if (toBottom) {
          //       // 对话引起的页面内容滚动，应该是定位到最新的提问，同时展示问答的数据
          //       let i = this.messages.length - 1
          //       let hasUserInput = false
          //       for (; i > prevIndex; i--) {
          //         const message = this.messages[i]
          //         if (message.from === 2 || message.type === 0) {
          //           hasUserInput = true
          //           break
          //         }
          //       }
          //       prevIndex = i
          //       console.log(prevIndex)
          //       const maxScrollY = this.scroller.maxScrollY
          //       const offset = hasUserInput
          //         ? Math.max(
          //           -this.$refs.chatBubble[i].$el.offsetTop + 10,
          //           maxScrollY
          //         )
          //         : maxScrollY
          //       console.log(maxScrollY, offset)
          //       this.scroller.scrollTo(0, offset, 0)
          //       prevIndex = this.messages.length - 1
          //       // 在首次进入页面时我们给window.name设置一个固定值(isRefresh)
          //       window.name = 'refresh'
          //     }
          //   }
          // }, 200)
        },
        300,
        {
          leading: false,
          trailing: true
        }
      )
    })()
  },
  mounted () {
    this.$store.dispatch('initApp')
    this.$store.commit('updateLayout', {
      footerHeight: 50
    })
  },
  deactivated () {},
  activated () {
    if (this.messages.length > 0) {
      this.scrollToEnd(true)
      console.log('page actived')
      this.$store.commit('updateLayout', {
        footerHeight: 50
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-list-panel {
  box-sizing: border-box;
  background-color: $chat-bgc;
  height: 100%;
  margin: 0 10px;
  padding: 10px 25px;
  border-radius: 5px;
  overflow: auto;
  .app-content {
    height: 100%;
  }
}
</style>
