<template>
  <!-- 单个会话消息 -->
  <div
    v-if="messageDataType === 'object'"
    :class="[
      'origin-' + (message.from === 2 ? 'user' : 'robot'),
      !isChild || message.from === 2 ? 'chat-wrapper' : '',
      parentClass
    ]"
  >
    <component
      :is="bubbleElem"
      :message="message"
      :isChild.sync="isFolder"
      :class="'bubble-type-' + message.type"
      v-bind="$props"
    >
    </component>
  </div>
  <div v-else class="component-root" :class="isFolder ? 'chat-wrapper' : ''">
    <div
      :class="[isFolder && 'chat-robot-box', isBlock && 'is-block']"
      :style="isBlock && 'display: block;'"
    >
      <!-- 会话消息组合 -->
      <component
        v-for="msg in message"
        :key="msg.id"
        :is="bubbleElem"
        :isChild.sync="isFolder"
        :message="msg"
        v-bind="$props"
        :isBlock="isBlock"
        >{{ msg.type }}</component
      >
    </div>
  </div>
</template>

<script>
import './Bubbles/style.scss'
import simpleIngoreTypes from '@/views/Chat/simpleIngoreTypes'

const typeOf = (input) => {
  const typeGetter = /\[object\s([a-z]+)\]/
  return Object.prototype.toString
    .call(input)
    .toLowerCase()
    .replace(typeGetter, '$1')
}

export default {
  name: 'ChatBubble',
  props: {
    message: [Array, Object, Boolean],
    isChild: Boolean,
    index: Number
  },
  data () {
    return {
      isFolder: false,
      versionCareIgnores: simpleIngoreTypes,
      bubbleLoader: {
        '-1': () => import('@/components/Bubbles/Tip'), // 灰色提示文案：背景文字
        0: () => import('@/components/Bubbles/Text'), // 欢迎语
        1: () => import('@/components/Bubbles/Part'), // l 首页推荐业务列表
        2: () => import('@/components/Bubbles/Hot'), // 业务,
        3: {
          loader: () => import('@/components/Bubbles/Hot'), // 考试列表
          options: {
            title () {
              return '请选择您要咨询的考试名称'
            }
          }
        },
        4: () => import('@/components/Bubbles/Download'), // 下载列表
        7: () => import('@/components/Bubbles/RelQuestion'), // 相关问题列表
        8: () => import('@/components/Bubbles/RelQuestion'), // 您可能关注下列相关问题
        10: () => import('@/components/Bubbles/Image'), // 您可能关注下列相关问题
        12: () => import('@/components/Bubbles/RelBusiness'), // 相关业务
        17: () => import('@/components/Bubbles/Part'), // 栏目
        18: () => import('@/components/Bubbles/Tip'), // 热点咨询话术 18;
        20: () => import('@/components/Bubbles/Download'), // 地址选择
        21: () => import('@/components/Bubbles/DistrictSelector'), // 地址选择
        22: () => import('@/components/Bubbles/Contact'), // 联系方式
        23: () => import('@/components/Bubbles/SubQuestion'), // 二级问题
        888: () => import('@/components/Bubbles/TextResponse'), // 文本答案
        exam: () => import('@/components/Bubbles/Exam'), // 业务对应的考试列表
        feedback: () => import('@/components/Bubbles/Feedback'), // 反馈标记
        loading: () => import('@/components/Bubbles/Loading'), // 提问的 loading
        commit: () => import('@/components/Bubbles/Comment'),
        anchor: () => import('@/components/Bubbles/Anchor')
      }
    }
  },
  computed: {
    uiMode () {
      return this.$store.state.uiMode
    },
    messageDataType () {
      return typeOf(this.message)
    },
    isBlock () {
      if (typeOf(this.message) === 'array') {
        return (
          this.message.filter((item) => item.type !== 0 || item.type !== 888)
            .length > 0
        )
      } else {
        return false
      }
    },
    parentClass () {
      return this.getRenderComponent() ? 'init-component' : 'unknown-component'
    },
    bubbleElem () {
      return (
        this.getRenderComponent() || {
          render (h) {
            return h('div', [
              h(
                'div',
                {
                  class: 'unknown-type'
                },
                'Bubble component NOT FOUND !!!'
              ),
              h('div', this.message)
            ])
          }
        }
      )
    }
  },
  methods: {
    getRenderComponent () {
      if (this.messageDataType === 'array') {
        return () =>
          new Promise((resolve) => {
            import('@/components/ChatBubble').then((mod) => {
              const exports = mod.default
              this.isFolder = true
              resolve(exports)
            })
          })
      } else {
        const type = this.message.type || 0
        const loader = this.bubbleLoader[type]

        if (this.uiMode === 2 && this.versionCareIgnores.indexOf(type) >= 0) {
          return null
        }

        if (typeOf(loader) === 'object') {
          const { loader: loaderFn, options } = loader

          return () =>
            new Promise((resolve) => {
              loaderFn().then((mod) => {
                const exports = mod.default
                const data = {}
                const computed = {}

                if (options) {
                  Object.keys(options).forEach((key) => {
                    const value = options[key]

                    if (typeOf(value) === 'function') {
                      computed[key] = value
                    } else {
                      data[key] = value
                    }
                  })
                }

                // 混入赋值
                exports.mixins = [
                  {
                    data () {
                      return data
                    },
                    computed
                  }
                ]
                resolve(exports)
              })
            })
        } else if (typeOf(loader) === 'function') {
          return loader
        }
        return null
      }
    }

  }
}
</script>
